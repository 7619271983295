import React, { useEffect, useState } from 'react';

import { Routes } from './routes/Routes';
import { AnimatedSplashScreen, AudioPlayer } from './services';

import { sleep } from '@shared/utils';

import { SintoniaThemeRuntime, useTheme } from '@ui/services';

export interface BootstrapParams {
  onBootstrapped?: () => void;
}

export function Bootstrap({ onBootstrapped }: BootstrapParams) {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const { theme } = useTheme();

  async function setupBootStrap() {
    SintoniaThemeRuntime.setTheme(theme);
    await sleep();
    await AudioPlayer.setup();
    setIsReady(true);
  }

  useEffect(() => {
    setupBootStrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isFinished) {
    return (
      <AnimatedSplashScreen
        ready={isReady}
        onAnimationEnd={() => setIsFinished(true)}
      />
    );
  }

  return <Routes onReady={onBootstrapped} />;
}
