import React from 'react';
import { Linking, Pressable } from 'react-native';
import { Markdown, Text, XBox, YBox } from '@ui/components';

import { Screen } from '@ui/components/Screen/Screen';

const aboutContent = `
  O aplicativo oficial da Rádio **Sintonia FM 98,5** traz a melhor programação musical e informativa para o seu smartphone.

  Com ele, você pode:

  **Ouvir a rádio ao vivo:** Curta a programação da **Sintonia** em tempo real, onde quer que você esteja.

  **Acessar as redes sociais:** Siga a **Sintonia** nas redes sociais e fique por dentro de todas as novidades.

  E muitos outros recursos.
`;

export function AboutScreen() {
  return (
    <Screen title="Sobre o aplicativo" canGoBack scrollable>
      <Markdown content={aboutContent} />
      <YBox flex={1} justifyContent="end" alignItems="center">
        <XBox justifyContent="center" alignItems="center">
          <Text variant="paragraphCaption">Desenvolvido com ❤️ por</Text>
          <Pressable
            onPress={() => Linking.openURL('https://migueldaipre.dev')}>
            <Text variant="paragraphCaptionBold"> migueldaipre.dev</Text>
          </Pressable>
        </XBox>
      </YBox>
    </Screen>
  );
}
