import React from 'react';
import {
  ActivityIndicator,
  Text,
  TouchableOpacity,
  type TouchableOpacityProps,
} from 'react-native';

import { createStyleSheet, useStyles } from '@ui/services';
import { hapticFeedback, HapticStyle } from '@services';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonType = 'primary' | 'outline' | 'link';

export interface ButtonProps extends TouchableOpacityProps {
  title: string;
  type?: ButtonType;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;

  /** Fill in the screen */
  fill?: boolean;

  /**
   * Use haptic feedback on press (Native Only)
   * If hapticStyle is not provided, the default (light) haptic style will be used
   */
  haptic?: boolean;
  hapticStyle?: HapticStyle;
}

export function Button({
  type = 'primary',
  size = 'medium',
  fill = false,
  title,
  loading,
  disabled,
  haptic,
  hapticStyle,
  onPressIn,
  style,
  ...props
}: ButtonProps) {
  const { styles, theme } = useStyles(stylesheet, {
    type,
    size,
    disabled: disabled ? type : undefined,
  });

  return (
    <TouchableOpacity
      disabled={disabled || loading}
      style={[styles.button(fill), style]}
      onPressIn={event => {
        onPressIn?.(event);
        haptic &&
          hapticFeedback?.trigger(hapticStyle, { enableVibrateFallback: true });
      }}
      {...props}>
      <>
        {loading && (
          <ActivityIndicator
            color={
              type === 'primary'
                ? theme.colors.primaryContrast
                : theme.colors.primary
            }
          />
        )}
        <Text style={styles.buttonText}>{title}</Text>
      </>
    </TouchableOpacity>
  );
}

const stylesheet = createStyleSheet(theme => ({
  button: (fill: boolean) => ({
    width: fill ? '100%' : undefined,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    variants: {
      type: {
        primary: {
          backgroundColor: theme.colors.backgroundContrast,
        },
        outline: {
          backgroundColor: 'transparent',
          borderWidth: 2,
          borderColor: theme.colors.backgroundContrast,
        },
        link: {
          backgroundColor: 'transparent',
        },
      },
      size: {
        small: {
          padding: theme.spacings.s8,
          borderRadius: theme.borderRadii.s8,
          gap: theme.spacings.s4,
        },
        medium: {
          padding: theme.spacings.s12,
          borderRadius: theme.borderRadii.s12,
          gap: theme.spacings.s8,
        },
        large: {
          padding: theme.spacings.s16,
          borderRadius: theme.borderRadii.s16,
          gap: theme.spacings.s12,
        },
      },
      disabled: {
        primary: {
          backgroundColor: theme.colors.gray4,
        },
        outline: {
          borderWidth: 2,
          borderColor: theme.colors.gray4,
        },
        link: {},
      },
    },
  }),
  buttonText: {
    variants: {
      type: {
        primary: {
          color: theme.colors.background,
        },
        outline: {
          color: theme.colors.backgroundContrast,
        },
        link: {
          color: theme.colors.backgroundContrast,
        },
      },
      size: {
        small: {
          ...theme.components.textVariants.paragraphSmallBold,
        },
        medium: {
          ...theme.components.textVariants.paragraphMediumBold,
        },
        large: {
          ...theme.components.textVariants.paragraphBold,
        },
      },
      disabled: {
        primary: {
          color: theme.colors.gray2,
        },
        outline: {
          color: theme.colors.gray2,
        },
        link: {
          color: theme.colors.gray2,
        },
      },
    },
  },
}));
