import React from 'react';
import { Image as RNImage, ImageProps as RNImageProps } from 'react-native';
import { useTheme } from '@ui/services';

export interface ImageProps extends RNImageProps {
  name: ImageName;
}

const imageRegistry = {
  SaoPaulo: require('@assets/images/saoPaulo.png'),
  Sintonia: require('@assets/images/sintonia.png'),
};

const imageRegistryDarkMode = {
  SintoniaDarkMode: require('@assets/images/sintoniaDarkMode.png'),
};

export function Image({ name, ...props }: ImageProps) {
  const isDarkMode = useTheme().theme === 'dark';
  const sourceName = isDarkMode ? name + 'DarkMode' : name;

  const darkSource = imageRegistryDarkMode[sourceName as keyof ImageTypeDark];
  const source = imageRegistry[name as keyof ImageType];

  return darkSource ? (
    <RNImage {...props} source={darkSource} />
  ) : (
    <RNImage {...props} source={source} />
  );
}

type ImageTypeDark = typeof imageRegistryDarkMode;
export type ImageType = typeof imageRegistry;
export type ImageName = keyof ImageType;
