export type SharedColors = typeof sharedColors;
export const sharedColors = {
  // Theme
  redPrimary: '#ED1C24',
  redPrimaryLight: '#FF8489',

  // Grayscale
  black: '#121212',
  gray1: '#2F2F2F',
  gray2: '#7A7A7A',
  gray3: '#B3B3B3',
  gray4: '#EEEEEE',
  gray5: '#F5F5F5',
  white: '#FFFFFF',

  transparent: 'transparent',
} as const;
