import type React from 'react';
import { forwardRef } from 'react';
import { ScrollView, type ScrollViewProps, View } from 'react-native';

interface Props extends React.PropsWithChildren<{ backgroundColor: string }> {}

export function ViewContainer({ children, backgroundColor }: Props) {
  return <View style={{ backgroundColor, flex: 1 }}>{children}</View>;
}

interface ScrollViewContainerProps extends ScrollViewProps {
  backgroundColor: string;
}

export const ScrollViewContainer = forwardRef<
  ScrollView,
  ScrollViewContainerProps
>(({ children, backgroundColor }, ref) => {
  return (
    <ScrollView
      ref={ref}
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor, flex: 1 }}>
      {children}
    </ScrollView>
  );
});
