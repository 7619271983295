import { State, usePlaybackState } from 'react-native-track-player';

export function useAudioPlayerEvents() {
  const { state } = usePlaybackState();

  return {
    isWaiting: state === undefined,
    isLoading: state === State.Loading || state === State.Buffering,
    isPlaying: state === State.Playing,
    isPaused: state === State.Paused,
    isStopped: state === State.Stopped,
  };
}
