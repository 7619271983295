import type {
  DimensionValueProperties,
  NumberProperties,
} from '../helpers/types';
import { type Spacings, spacings } from './spacings';

import type {
  DimensionValue,
  FlexStyle as RNFlexStyle,
} from 'react-native/types';

type UnsupportedFlexStyleProps = 'flex' | 'flexGrow' | 'flexShrink' | 'zIndex';
type SupportedFlexStyleProps = Omit<RNFlexStyle, UnsupportedFlexStyleProps>;

type FlexStyle =
  | NumberProperties<SupportedFlexStyleProps>
  | DimensionValueProperties<SupportedFlexStyleProps>;

type NumberFlexStyle = Spacings | number;
type DimensionFlexStyle = Spacings | DimensionValue;

export type FlexStylesProps = Partial<
  Record<
    Extract<FlexStyle, string | number>,
    NumberFlexStyle | DimensionFlexStyle
  >
>;

const availableStyles = [
  'borderBottomWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'borderTopWidth',
  'borderWidth',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginHorizontal',
  'marginVertical',
  'marginStart',
  'marginEnd',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingHorizontal',
  'paddingVertical',
  'paddingStart',
  'paddingEnd',
  'width',
  'height',
  'minWidth',
  'minHeight',
  'maxWidth',
  'maxHeight',
  'top',
  'right',
  'bottom',
  'left',
  'start',
  'end',
  'columnGap',
  'rowGap',
  'gap',
  'borderEndWidth',
  'borderStartWidth',
  'flexBasis',
] satisfies (keyof FlexStylesProps)[];

export const flexStyles = {
  flexStyles: (props: FlexStylesProps) => {
    const styles = {};

    for (const availableStyle of availableStyles) {
      if (props[availableStyle]) {
        // @ts-ignore
        styles[availableStyle] =
          spacings[props[availableStyle] as Spacings] ?? props[availableStyle];
      }
    }

    return styles;
  },
};
