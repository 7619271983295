import { MMKV } from 'react-native-mmkv';

export interface IStorage {
  getItem: <T = unknown>(key: string) => Promise<T | null>;
  setItem: <T>(key: string, value: T) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
}

const MMKVInstance = new MMKV();
export const storage: IStorage = {
  getItem: (key: string) => {
    const item = MMKVInstance.getString(key);
    if (item) {
      return JSON.parse(item);
    }

    return null;
  },
  setItem: async (key: string, value: any) => {
    MMKVInstance.set(key, JSON.stringify(value));
  },
  removeItem: async (key: string) => {
    MMKVInstance.delete(key);
  },
};
