export const typography = {
  satoshiBold: 'Satoshi-Bold',
  satoshiRegular: 'Satoshi-Regular',
  satoshiLight: 'Satoshi-Light',
} as const;

export type TextVariants = keyof typeof textVariants;
export const textVariants = {
  headlineLarge: {
    fontFamily: typography.satoshiBold,
    fontSize: 32,
    lineHeight: 38.4,
  },
  headlineMedium: {
    fontFamily: typography.satoshiBold,
    fontSize: 22,
    lineHeight: 26.4,
  },
  headlineSmall: {
    fontFamily: typography.satoshiBold,
    fontSize: 18,
    lineHeight: 23.4,
  },
  paragraph: {
    fontFamily: typography.satoshiRegular,
    fontSize: 18,
    lineHeight: 25.2,
  },
  paragraphBold: {
    fontFamily: typography.satoshiBold,
    fontSize: 18,
    lineHeight: 25.2,
  },
  paragraphLight: {
    fontFamily: typography.satoshiLight,
    fontSize: 18,
    lineHeight: 25.2,
  },
  paragraphMedium: {
    fontFamily: typography.satoshiRegular,
    fontSize: 16,
    lineHeight: 22.4,
  },
  paragraphMediumBold: {
    fontFamily: typography.satoshiBold,
    fontSize: 16,
    lineHeight: 22.4,
  },
  paragraphMediumLight: {
    fontFamily: typography.satoshiLight,
    fontSize: 16,
    lineHeight: 22.4,
  },
  paragraphSmall: {
    fontFamily: typography.satoshiRegular,
    fontSize: 14,
    lineHeight: 19.6,
  },
  paragraphSmallBold: {
    fontFamily: typography.satoshiBold,
    fontSize: 14,
    lineHeight: 19.6,
  },
  paragraphSmallLight: {
    fontFamily: typography.satoshiLight,
    fontSize: 14,
    lineHeight: 19.6,
  },
  paragraphCaption: {
    fontFamily: typography.satoshiRegular,
    fontSize: 12,
    lineHeight: 16.8,
  },
  paragraphCaptionBold: {
    fontFamily: typography.satoshiBold,
    fontSize: 12,
    lineHeight: 16.8,
  },
  paragraphCaptionLight: {
    fontFamily: typography.satoshiLight,
    fontSize: 12,
    lineHeight: 16.8,
  },
  paragraphCaptionSmall: {
    fontFamily: typography.satoshiRegular,
    fontSize: 10,
    lineHeight: 14,
  },
  paragraphCaptionSmallBold: {
    fontFamily: typography.satoshiBold,
    fontSize: 10,
    lineHeight: 14,
  },
  paragraphCaptionSmallLight: {
    fontFamily: typography.satoshiLight,
    fontSize: 10,
    lineHeight: 14,
  },
} as const;
