import { Config, ConfigAPI } from './configTypes';

function toConfig(configApi: ConfigAPI): Config {
  return {
    id: String(configApi.data.id),
    title: configApi.data.title,
    slogan: configApi.data.slogan,
    privacy: configApi.data.privacy,
    streamUrl: configApi.data.streamUrl,
    streamTitle: configApi.data.streamTitle,
    streamArtwork: {
      url: configApi.data?.streamArtwork?.url ?? '',
      name: configApi.data?.streamArtwork?.name ?? '',
      width: configApi.data?.streamArtwork?.width ?? 0,
      height: configApi.data.streamArtwork?.height ?? 0,
    },
  };
}

export const configAdapter = {
  toConfig,
};
