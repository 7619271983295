import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { Platform } from '@shared/platform';

import {
  PlayerScreen,
  OnboardingScreen,
  PrivacyScreen,
  AboutScreen,
  useAlreadyViewOnboarding,
} from '@screens';

import { DrawerButtonLeft } from './components/DrawerButtonLeft';
import { DrawerContent } from './components/DrawerContent';

export const HomeStack = createDrawerNavigator({
  drawerContent: props => <DrawerContent {...props} />,
  screenOptions: {
    headerLeft: () => <DrawerButtonLeft />,
  },
  screens: {
    Player: {
      options: {
        title: 'Rádio - Sintonia FM 98.5',
        headerTitle: 'Rádio',
        headerTransparent: true,
        headerShadowVisible: false,
      },
      screen: PlayerScreen,
    },
  },
});

export const RootStack = createNativeStackNavigator({
  initialRouteName: 'Onboarding',
  screenOptions: {
    headerBackVisible: false,
    headerShadowVisible: false,
    statusBarTranslucent: true,
    navigationBarColor: 'transparent',
    statusBarBackgroundColor: 'transparent',
  },
  groups: {
    Config: {
      screens: {
        Privacy: {
          options: {
            title: 'Política de Privacidade',
          },
          screen: PrivacyScreen,
        },
        About: {
          options: {
            title: 'Sobre',
          },
          screen: AboutScreen,
        },
      },
    },
  },
  screens: {
    Onboarding: {
      options: {
        headerShown: false,
      },
      screen: OnboardingScreen,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if: () => !useAlreadyViewOnboarding() && Platform.isNative,
    },
    Home: {
      options: {
        headerShown: false,
      },
      screen: HomeStack,
    },
  },
});
