import { useCallback } from 'react';
import { useAppNavigation } from '@hooks';

export function useResetOnboardingNavigation() {
  const navigation = useAppNavigation();

  const reset = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Home',
        },
      ],
    });
  }, [navigation]);

  return {
    reset,
  };
}
