import { Platform } from '@shared/platform';
import TrackPlayer, {
  AppKilledPlaybackBehavior,
  Event,
  Capability,
} from 'react-native-track-player';

import type { PlayerOptions, AddTrack } from 'react-native-track-player';

const setupPlayer = async (
  options: Parameters<typeof TrackPlayer.setupPlayer>[0],
) => {
  const setup = async () => {
    try {
      await TrackPlayer.setupPlayer(options);
    } catch (error) {
      return (error as Error & { code?: string }).code;
    }
  };
  while ((await setup()) === 'android_cannot_setup_player_in_background') {
    // A timeout will mostly only execute when the app is in the foreground,
    // and even if we were in the background still, it will reject the promise
    // and we'll try again:
    await new Promise<void>(resolve => setTimeout(resolve, 1));
  }
};

export class AudioPlayer {
  private constructor() {}

  private static async serviceHandler() {
    TrackPlayer.addEventListener(Event.RemotePause, () => AudioPlayer.pause());
    TrackPlayer.addEventListener(Event.RemotePlay, () => AudioPlayer.play());
  }

  public static init() {
    TrackPlayer.registerPlaybackService(() => AudioPlayer.serviceHandler);
  }

  public static async setup(
    options: PlayerOptions = { autoHandleInterruptions: true },
  ) {
    await setupPlayer(options);
    await TrackPlayer.updateOptions({
      android: {
        appKilledPlaybackBehavior: AppKilledPlaybackBehavior.ContinuePlayback,
      },
      capabilities: [Capability.Play, Capability.Pause],
      compactCapabilities: [Capability.Play, Capability.Pause],
    });
  }

  public static async addTracks(tracks: AddTrack[]) {
    await TrackPlayer.add(tracks);
  }

  public static async setQueue(tracks: AddTrack[]) {
    if (Platform.isWeb) {
      if (tracks?.length > 0) {
        await TrackPlayer.add(tracks[0]);
      }

      return;
    }

    await TrackPlayer.setQueue(tracks);
  }

  public static async getState() {
    return TrackPlayer.getPlaybackState();
  }

  public static async play() {
    await TrackPlayer.play();
  }

  public static async pause() {
    await TrackPlayer.pause();
  }
}
