import { socialAdapter } from './socialAdapter';
import { socialApi } from './socialApi';
import { Social } from './socialTypes';

async function getAllSocial(): Promise<Social> {
  const socialAPI = await socialApi.getAllSocial();
  return socialAdapter.toSocial(socialAPI);
}

export const socialService = {
  getAllSocial,
};
