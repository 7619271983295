import React from 'react';
import {
  ActivityIndicator,
  TouchableOpacity,
  type TouchableOpacityProps,
} from 'react-native';

import { createStyleSheet, useStyles } from '@ui/services';
import { hapticFeedback, HapticStyle } from '@services';

import { Icon, IconName } from '../Icon/Icon';
import { ThemeColors } from '@ui/theme';

export type ButtonIconSize = 'small' | 'medium' | 'large';
export type ButtonIconType = 'primary' | 'outline' | 'iconOnly';

export interface ButtonIconProps extends TouchableOpacityProps {
  icon: IconName;
  iconColor?: keyof ThemeColors;
  type?: ButtonIconType;
  size?: ButtonIconSize;
  loading?: boolean;

  /**
   * Use haptic feedback on press (Native Only)
   * If hapticStyle is not provided, the default (light) haptic style will be used
   */
  haptic?: boolean;
  hapticStyle?: HapticStyle;
}

const iconTypeToColor: Record<ButtonIconType, keyof ThemeColors> = {
  primary: 'background',
  outline: 'backgroundContrast',
  iconOnly: 'backgroundContrast',
};

export function ButtonIcon({
  type = 'primary',
  size = 'medium',
  icon,
  iconColor,
  loading,
  haptic,
  hapticStyle,
  onPressIn,
  style,
  ...props
}: ButtonIconProps) {
  const {
    styles,
    theme: { colors },
  } = useStyles(stylesheet, {
    type,
    size,
  });

  return (
    <TouchableOpacity
      disabled={loading}
      style={[styles.buttonIcon, style]}
      onPressIn={event => {
        onPressIn?.(event);
        haptic &&
          hapticFeedback?.trigger(hapticStyle, { enableVibrateFallback: true });
      }}
      {...props}>
      <>
        {loading ? (
          <ActivityIndicator
            color={iconColor ?? colors[iconTypeToColor[type]]}
          />
        ) : (
          <Icon name={icon} color={iconColor ?? iconTypeToColor[type]} />
        )}
      </>
    </TouchableOpacity>
  );
}

const stylesheet = createStyleSheet(theme => ({
  buttonIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    variants: {
      type: {
        primary: {
          backgroundColor: theme.colors.backgroundContrast,
        },
        outline: {
          backgroundColor: 'transparent',
          borderWidth: 2,
          borderColor: theme.colors.backgroundContrast,
        },
        iconOnly: {
          backgroundColor: 'transparent',
        },
      },
      size: {
        small: {
          height: 56,
          width: 56,
          borderRadius: 56,
        },
        medium: {
          height: 68,
          width: 68,
          borderRadius: 68,
        },
        large: {
          height: 80,
          width: 80,
          borderRadius: 80,
        },
      },
    },
  },
}));
