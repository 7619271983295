import React from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';

import { queryClient } from './queryClient';
import { storage } from '@services';

const persister = createAsyncStoragePersister({ storage: storage });
export function ReactQueryProvider({ children }: React.PropsWithChildren) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}>
      {children}
    </PersistQueryClientProvider>
  );
}
