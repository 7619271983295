export type Spacings = keyof typeof spacings;
export const spacings = {
  s0: 0,
  s4: 4,
  s8: 8,
  s12: 12,
  s16: 16,
  s20: 20,
  s24: 24,
  s28: 28,
  s32: 32,
  s36: 36,
  s40: 40,
  s44: 44,
  s48: 48,
  s52: 52,
  s56: 56,
} as const;
