import React, { useState } from 'react';
import { Animated, Dimensions } from 'react-native';

import { Platform } from '@shared/platform';
import { splashScreen } from './splashScreen';

const useNativeDriver = Platform.isNative;

type Props = {
  ready: boolean;
  onAnimationEnd: () => void;
};

export const AnimatedSplashScreen = ({ ready, onAnimationEnd }: Props) => {
  const [opacity] = useState(() => new Animated.Value(1));
  const [translateY] = useState(() => new Animated.Value(0));

  const { container, logo } = splashScreen.useHideAnimation({
    ready,
    manifest: require('@assets/splash/manifest.json'),
    logo: require('@assets/splash/logo.png'),
    statusBarTranslucent: true,
    navigationBarTranslucent: false,
    animate: () => {
      const { height } = Dimensions.get('window');

      Animated.stagger(250, [
        Animated.spring(translateY, {
          useNativeDriver,
          toValue: -50,
        }),
        Animated.spring(translateY, {
          useNativeDriver,
          toValue: height,
        }),
      ]).start();

      Animated.timing(opacity, {
        useNativeDriver,
        toValue: 0,
        duration: 150,
        delay: 350,
      }).start(() => {
        onAnimationEnd();
      });
    },
  });

  return (
    <Animated.View {...container} style={[container.style, { opacity }]}>
      <Animated.Image
        {...logo}
        style={[logo.style, { transform: [{ translateY }] }]}
      />
    </Animated.View>
  );
};
