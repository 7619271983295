import { Social, SocialAPI } from './socialTypes';

function toSocial(socialApi: SocialAPI): Social {
  return {
    id: String(socialApi.data.id),
    youtube: socialApi.data.youtube,
    instagram: socialApi.data.instagram,
    facebook: socialApi.data.facebook,
    website: socialApi.data.website,
  };
}

export const socialAdapter = {
  toSocial,
};
