import { api } from '@infra/api';

import { ConfigAPI } from './configTypes';

async function getAllConfig(): Promise<ConfigAPI> {
  const response = await api.get<ConfigAPI>('config?populate=*');
  return response.data;
}

export const configApi = {
  getAllConfig,
};
