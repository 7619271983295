import './ui/setup';
import React, { useCallback } from 'react';

import { splashScreen } from './services';
import { Bootstrap } from './Bootstrap';

export function App() {
  const onReady = useCallback(() => {
    splashScreen.hide();
  }, []);

  return <Bootstrap onBootstrapped={onReady} />;
}
