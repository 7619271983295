import React from 'react';

import { Image, ImageBackground, XBox, YBox } from '@ui/components';
import { useStyles, createStyleSheet } from '@ui/services';

import { AudioPlayer } from '@services';
import { useGetAllConfig } from '@domain';
import { useQueryEvents } from '@infra';

import { PlayerControls } from './components/PlayerControls';
import { Platform } from '@shared/platform';

export function PlayerScreen() {
  const { styles } = useStyles(stylesheet);
  const { configQuery } = useGetAllConfig();

  useQueryEvents(configQuery, {
    onSuccess: async ({ streamUrl, streamTitle, streamArtwork }) => {
      await AudioPlayer.setQueue([
        { url: streamUrl, title: streamTitle, artwork: streamArtwork.url },
      ]);
    },
  });

  return (
    <ImageBackground>
      <YBox flex={1} justifyContent="end" alignItems="center">
        <Image
          name="Sintonia"
          resizeMode="contain"
          style={styles.sintoniaImage}
        />
      </YBox>
      <XBox flex={1} padding="s44" justifyContent="center" alignItems="end">
        <PlayerControls />
      </XBox>
    </ImageBackground>
  );
}

const stylesheet = createStyleSheet(() => ({
  sintoniaImage: {
    width: '50%',
    ...(Platform.isWeb ? { height: '25%' } : null),
  },
}));
