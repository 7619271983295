import React from 'react';
import { View, type ViewProps, type ViewStyle } from 'react-native';

import type {
  AlignItemsVariant,
  FlexStylesProps,
  JustifyContentVariant,
  ViewStylesProps,
} from '@ui/theme';

import { createStyleSheet, useStyles } from '@ui/services';

export interface YBoxProps extends ViewProps, FlexStylesProps, ViewStylesProps {
  flex?: ViewStyle['flex'];
  flexShrink?: ViewStyle['flexShrink'];
  flexGrow?: ViewStyle['flexGrow'];
  zIndex?: ViewStyle['zIndex'];
  alignItems?: AlignItemsVariant;
  justifyContent?: JustifyContentVariant;
}

export function YBox({
  flex,
  flexShrink,
  flexGrow,
  zIndex,
  alignItems,
  justifyContent,
  children,
  style,
  ...props
}: YBoxProps) {
  const { styles } = useStyles(stylesheet, { alignItems, justifyContent });

  return (
    <View
      {...props}
      style={[
        styles.container,
        styles.flexStyles(props),
        styles.viewStyles(props),
        { flex, flexShrink, flexGrow, zIndex },
        style,
      ]}>
      {children}
    </View>
  );
}

const stylesheet = createStyleSheet(theme => ({
  container: {
    flexDirection: 'column',
    backgroundColor: theme.colors.transparent,
    variants: {
      ...theme.components.boxVariants,
    },
  },
  ...theme.components.flexStyles,
  viewStyles: (props: ViewStylesProps) =>
    theme.components.viewStyles.viewStyles(props, theme.colors),
}));
