import type { ColorValueProperties } from '../helpers/types';
import type { ThemeColors } from './theme';

import type { ViewStyle as RNViewStyle } from 'react-native/types';

type UnsupportedViewStyleProps =
  | 'alignContent'
  | 'alignItems'
  | 'alignSelf'
  | 'backfaceVisibility'
  | 'borderStyle'
  | 'display'
  | 'direction'
  | 'flexDirection'
  | 'flexWrap'
  | 'justifyContent'
  | 'overflow'
  | 'position'
  | 'pointerEvents'
  | 'borderCurve';

type SupportedViewStyleProps = Omit<RNViewStyle, UnsupportedViewStyleProps>;

type ViewStyle = ColorValueProperties<SupportedViewStyleProps>;

export type ViewStylesProps = Partial<
  Record<Extract<ViewStyle, string>, keyof ThemeColors>
>;

const availableStyles = [
  'backgroundColor',
  'borderColor',
  'borderBlockColor',
  'borderBlockEndColor',
  'borderBlockStartColor',
  'borderBottomColor',
  'borderEndColor',
  'borderLeftColor',
  'borderRightColor',
  'borderStartColor',
  'borderTopColor',
  'shadowColor',
] satisfies (keyof ViewStylesProps)[];

export const viewStyles = {
  viewStyles: (props: ViewStylesProps, colors: ThemeColors) => {
    const styles = {};

    for (const availableStyle of availableStyles) {
      if (props[availableStyle]) {
        // @ts-ignore
        styles[availableStyle] =
          colors[props[availableStyle] as keyof ThemeColors] ??
          props[availableStyle];
      }
    }

    return styles;
  },
};
