import React from 'react';
import { ButtonIcon } from '@ui/components';
import { AudioPlayer, useAudioPlayerEvents } from '@services';

export function PlayerControls() {
  const { isLoading, isWaiting, isPlaying } = useAudioPlayerEvents();

  async function play() {
    await AudioPlayer.play();
  }

  async function pause() {
    await AudioPlayer.pause();
  }

  return (
    <ButtonIcon
      haptic
      hapticStyle="medium"
      loading={isLoading || isWaiting}
      icon={isPlaying ? 'pause' : 'play'}
      onPress={isPlaying ? pause : play}
      size="large"
    />
  );
}
