import { Platform as RNPlatform } from 'react-native';

const isIOS = RNPlatform.OS === 'ios';
const isAndroid = RNPlatform.OS === 'android';
const isWeb = RNPlatform.OS === 'web';

const isNative = isIOS || isAndroid;
const devicePlatform = isIOS ? 'ios' : isAndroid ? 'android' : 'web';

export type PlatformType = {
  isIOS: boolean;
  isAndroid: boolean;
  isWeb: boolean;
  isNative: boolean;
  devicePlatform: 'ios' | 'android' | 'web';
};

export const Platform: PlatformType = {
  isIOS,
  isAndroid,
  isWeb,
  isNative,
  devicePlatform,
};
