import React from 'react';
import {
  ImageBackground as RNImageBackground,
  ImageBackgroundProps as RNImageBackgroundProps,
} from 'react-native';

import { createStyleSheet, useStyles } from '@ui/services';

interface ImageBackgroundProps extends Omit<RNImageBackgroundProps, 'source'> {}

export function ImageBackground({
  children,
  style,
  ...props
}: ImageBackgroundProps) {
  const { styles } = useStyles(stylesheet);

  return (
    <RNImageBackground
      style={[styles.image, style]}
      source={require('@assets/images/backgroundRadial.png')}
      {...props}>
      {children}
    </RNImageBackground>
  );
}

const stylesheet = createStyleSheet(() => ({
  image: {
    flex: 1,
  },
}));
