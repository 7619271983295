export {
  createStyleSheet,
  useStyles,
  useInitialTheme as useInitialSintoniaTheme,
  UnistylesRuntime as SintoniaThemeRuntime,
} from 'react-native-unistyles';

export type { UnistylesThemes as SintoniaThemes } from 'react-native-unistyles';

export * from './unistyles/useTheme';
