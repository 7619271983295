import { api } from '@infra/api';

import { SocialAPI } from './socialTypes';

async function getAllSocial(): Promise<SocialAPI> {
  const response = await api.get<SocialAPI>('social');
  return response.data;
}

export const socialApi = {
  getAllSocial,
};
