import React from 'react';
import type { StaticScreenProps } from '@react-navigation/native';

import { Markdown } from '@ui/components';
import { Screen } from '@ui/components/Screen/Screen';

type PrivacyScreenProps = StaticScreenProps<{
  content: string;
}>;

export function PrivacyScreen({ route }: PrivacyScreenProps) {
  const {
    params: { content },
  } = route;

  return (
    <Screen title="Política de Privacidade" canGoBack scrollable>
      <Markdown content={content} />
    </Screen>
  );
}
