import React from 'react';
import { Pressable } from 'react-native';

import type { ThemeColors } from '@ui/theme';
import { useStyles } from '@ui/services';

import { ArrowRightIcon } from '@assets/icons/ArrowRight';
import { ArrowLeftIcon } from '@assets/icons/ArrowLeft';
import { PauseIcon } from '@assets/icons/Pause';
import { PlayIcon } from '@assets/icons/Play';
import { MoonIcon } from '@assets/icons/Moon';
import { SunIcon } from '@assets/icons/Sun';
import { InstagramIcon } from '@assets/icons/Instagram';
import { FacebookIcon } from '@assets/icons/Facebook';
import { ComputerIcon } from '@assets/icons/Computer';
import { MenuIcon } from '@assets/icons/Menu';
import { YoutubeIcon } from '@assets/icons/Youtube';

export interface IconBase {
  size?: number;
  color?: string;
}

export interface IconProps {
  name: IconName;
  color?: keyof ThemeColors;
  size?: number;
  onPress?: () => void;
}
export function Icon({
  name,
  color = 'backgroundContrast',
  size,
  onPress,
}: IconProps) {
  const {
    theme: { colors },
  } = useStyles();

  const SVGIcon = iconRegistry[name];

  if (onPress) {
    return (
      <Pressable hitSlop={10} onPress={onPress}>
        <SVGIcon color={colors[color]} size={size} />
      </Pressable>
    );
  }

  return <SVGIcon color={colors[color]} size={size} />;
}

const iconRegistry = {
  arrowRight: ArrowRightIcon,
  arrowLeft: ArrowLeftIcon,
  pause: PauseIcon,
  play: PlayIcon,
  moon: MoonIcon,
  sun: SunIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  website: ComputerIcon,
  menu: MenuIcon,
  youtube: YoutubeIcon,
};

export type IconType = typeof iconRegistry;
export type IconName = keyof IconType;
