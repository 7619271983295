import React from 'react';
import { Linking } from 'react-native';

import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
} from '@react-navigation/drawer';

import { Platform } from '@shared/platform';

import { ButtonIcon, Icon, Text, YBox } from '@ui/components';
import { useStyles, createStyleSheet, useTheme } from '@ui/services';

import { useGetAllConfig, useGetAllSocial } from '../../domain';
import { useAppNavigation } from '../../hooks/index';

const { isNative } = Platform;

interface DrawerContentProps extends DrawerContentComponentProps {}

export function DrawerContent(props: DrawerContentProps) {
  const { toggle, theme } = useTheme();

  return (
    <DrawerContentScrollView {...props} showsVerticalScrollIndicator={false}>
      <YBox justifyContent="between">
        <YBox>
          <SocialList />
          <ConfigList />
        </YBox>
        <YBox justifyContent="end">
          <ButtonIcon
            icon={theme === 'dark' ? 'sun' : 'moon'}
            type="iconOnly"
            onPress={toggle}
          />
        </YBox>
      </YBox>
    </DrawerContentScrollView>
  );
}

function SocialList() {
  const { styles } = useStyles(stylesheet);
  const { socialData } = useGetAllSocial();

  return (
    <YBox gap="s4">
      <Text style={styles.itemTitle} variant="headlineMedium">
        Redes Sociais
      </Text>
      {socialData?.facebook && (
        <ItemList
          icon={() => <Icon name="facebook" />}
          label="Facebook"
          onPress={() => Linking.openURL(socialData.facebook)}
        />
      )}
      {socialData?.instagram && (
        <ItemList
          icon={() => <Icon name="instagram" />}
          label="Instagram"
          onPress={() => Linking.openURL(socialData.instagram)}
        />
      )}
      {socialData?.youtube && (
        <ItemList
          icon={() => <Icon name="youtube" />}
          label="Youtube"
          onPress={() => Linking.openURL(socialData.youtube)}
        />
      )}
      {isNative && socialData?.website && (
        <ItemList
          icon={() => <Icon name="website" />}
          label="Site"
          onPress={() => Linking.openURL(socialData.website)}
        />
      )}
    </YBox>
  );
}

function ConfigList() {
  const { styles } = useStyles(stylesheet);
  const { navigate } = useAppNavigation();

  const { configData } = useGetAllConfig();

  return (
    <YBox gap="s4">
      <Text style={styles.itemTitle} variant="headlineMedium">
        Configurações
      </Text>
      {configData?.privacy && (
        <ItemList
          label="Política de Privacidade"
          onPress={() => navigate('Privacy', { content: configData.privacy })}
        />
      )}

      <ItemList label="Sobre o aplicativo" onPress={() => navigate('About')} />
    </YBox>
  );
}

interface ItemListProps {
  onPress: () => void;
  label: string;
  icon?: () => React.ReactNode;
}

function ItemList({ label, onPress, icon }: ItemListProps) {
  const { styles } = useStyles(stylesheet);

  return (
    /* @ts-ignore */
    <DrawerItem
      style={styles.item}
      onPress={onPress}
      label={label}
      icon={icon}
    />
  );
}

const stylesheet = createStyleSheet(theme => ({
  itemTitle: {
    margin: theme.spacings.s16,
  },
  item: {
    backgroundColor: theme.colors.backgroundContrastTransparent,
  },
  logo: {
    flex: 1,
    marginHorizontal: theme.spacings.s16,
    justifyContent: 'flex-start',
    width: '50%',
  },
}));
