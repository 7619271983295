import { UnistylesRegistry } from 'react-native-unistyles';

import { breakpoints } from '../../theme/breakpoints';
import { darkTheme, lightTheme } from '../../theme/theme';

type AppBreakpoints = typeof breakpoints;
type AppThemes = {
  readonly light: typeof lightTheme;
  readonly dark: typeof darkTheme;
};

declare module 'react-native-unistyles' {
  export interface UnistylesThemes extends AppThemes {}
  export interface UnistylesBreakpoints extends AppBreakpoints {}
}

UnistylesRegistry.addThemes({
  light: lightTheme,
  dark: darkTheme,
}).addBreakpoints(breakpoints);
