import { configAdapter } from './configAdapter';
import { configApi } from './configApi';
import { Config } from './configTypes';

async function getAllConfig(): Promise<Config> {
  const configAPI = await configApi.getAllConfig();
  return configAdapter.toConfig(configAPI);
}

export const configService = {
  getAllConfig,
};
