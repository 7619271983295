import { useStringStorage } from '@services';
import { SintoniaThemeRuntime, SintoniaThemes } from '@ui/services';

export function useTheme() {
  const [colorScheme, setColorScheme] = useStringStorage('@theme:colorScheme');
  const theme = (colorScheme ??
    SintoniaThemeRuntime.colorScheme) as keyof SintoniaThemes;

  function toggle() {
    const isDarkMode = theme === 'dark';
    const newTheme = isDarkMode ? 'light' : 'dark';

    SintoniaThemeRuntime.setTheme(newTheme);
    setColorScheme(newTheme);
  }

  return {
    theme,
    toggle,
  };
}
