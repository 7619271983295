import React, { useCallback, useEffect } from 'react';
import { KeyboardAvoidingView, TouchableOpacity } from 'react-native';

import Animated, {
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import { useAppHeaderHeight, useAppNavigation, useAppSafeArea } from '@hooks';

import { Icon, Text, YBox, type YBoxProps } from '../';
import { useStyles } from '../../services';

import { Platform } from '@shared/platform';

import {
  ScrollViewContainer,
  ViewContainer,
} from './components/ScreenContainer';

const AnimatedScrollView =
  Animated.createAnimatedComponent(ScrollViewContainer);
const AnimatedText = Animated.createAnimatedComponent(Text);

interface ScreenProps extends React.PropsWithChildren<YBoxProps> {
  canGoBack?: boolean;
  scrollable?: boolean;
  title?: string;
  subTitle?: string;
}

export function Screen({
  children,
  canGoBack = false,
  scrollable = false,
  title,
  subTitle,
  style,
  ...props
}: ScreenProps) {
  const navigation = useAppNavigation();
  const headerHeight = useAppHeaderHeight();

  const { theme } = useStyles();
  const { top, bottom } = useAppSafeArea();

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: e => {
      scrollY.value = e.contentOffset.y;
    },
  });

  const animatedStyles = useAnimatedStyle(() => {
    const opacity = interpolate(
      scrollY.value,
      Platform.isAndroid
        ? [headerHeight, 100]
        : [theme.spacings.s36, headerHeight + top + theme.spacings.s20],
      [0, 1],
    );

    return {
      opacity,
    };
  });

  const BackButton = useCallback(
    () => (
      <TouchableOpacity
        onPress={navigation.goBack}
        style={{ marginLeft: Platform.isWeb ? 20 : 0 }}>
        <Icon name="arrowLeft" />
      </TouchableOpacity>
    ),
    [navigation],
  );

  useEffect(() => {
    if (navigation) {
      navigation.setOptions({
        headerStyle: {
          backgroundColor: theme.colors.background,
        },
        headerTitleAlign: canGoBack ? 'center' : 'left',
        headerLeft: canGoBack ? () => <BackButton /> : () => <></>,
        headerTitle: () => {
          const titleStyle = scrollable ? animatedStyles : undefined;
          return (
            <YBox style={{ flex: canGoBack ? 0 : 1 }}>
              <AnimatedText variant="headlineSmall" style={titleStyle}>
                {title}
              </AnimatedText>
            </YBox>
          );
        },
      });
    }
  }, [
    navigation,
    theme.colors.background,
    animatedStyles,
    BackButton,
    title,
    canGoBack,
    scrollable,
  ]);

  const behavior = Platform.isIOS ? 'padding' : undefined;
  const Container = scrollable ? AnimatedScrollView : ViewContainer;
  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior={behavior}>
      <Container
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        backgroundColor={theme.colors.background}>
        {title && scrollable && (
          <YBox gap="s8" paddingHorizontal="s20" paddingVertical="s4">
            <Text variant="headlineLarge">{title}</Text>
            {subTitle && (
              <Text variant="headlineSmall" color="gray2">
                {subTitle}
              </Text>
            )}
          </YBox>
        )}
        <YBox
          flex={1}
          paddingHorizontal="s20"
          paddingBottom={bottom}
          {...props}
          style={style}>
          {children}
        </YBox>
      </Container>
    </KeyboardAvoidingView>
  );
}
