export type AlignItemsVariant = 'start' | 'center' | 'end';
export type JustifyContentVariant =
  | 'start'
  | 'center'
  | 'end'
  | 'between'
  | 'around'
  | 'evenly';

export type BoxVariants = typeof boxVariants;

export const boxVariants = {
  alignItems: {
    start: {
      alignItems: 'flex-start',
    },
    center: {
      alignItems: 'center',
    },
    end: {
      alignItems: 'flex-end',
    },
  },
  justifyContent: {
    start: {
      justifyContent: 'flex-start',
    },
    center: {
      justifyContent: 'center',
    },
    end: {
      justifyContent: 'flex-end',
    },
    between: {
      justifyContent: 'space-between',
    },
    around: {
      justifyContent: 'space-around',
    },
    evenly: {
      justifyContent: 'space-evenly',
    },
  },
} as const;
