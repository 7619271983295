import React, { useCallback } from 'react';

import { ButtonIcon, Text, YBox, Image, ImageBackground } from '@ui/components';
import { createStyleSheet, useStyles } from '@ui/services';

import { Screen } from '@ui/components/Screen/Screen';

import { useBooleanStorage } from '@services';

import { useResetOnboardingNavigation } from './hooks/useResetOnboardingNavigation';

export function OnboardingScreen() {
  const { styles } = useStyles(stylesheet);
  const { reset } = useResetOnboardingNavigation();

  const [_, setVisited] = useBooleanStorage('@onboarding:visited');

  const navigate = useCallback(() => {
    setVisited(true);
    reset();
  }, [setVisited, reset]);

  return (
    <Screen paddingHorizontal="s0">
      <ImageBackground>
        <YBox
          flexShrink={1}
          height="100%"
          justifyContent="center"
          alignItems="center">
          <Image name="SaoPaulo" style={styles.saoPauloImage} />
        </YBox>
        <YBox flexGrow={1} height="auto">
          <YBox alignItems="center">
            <YBox padding="s44">
              <Text variant="headlineLarge" textAlign="center">
                A sintonia certa do seu rádio.
              </Text>
            </YBox>
            <YBox paddingTop="s56" alignItems="end">
              <ButtonIcon
                haptic
                icon="arrowRight"
                size="large"
                onPress={navigate}
              />
            </YBox>
          </YBox>
        </YBox>
      </ImageBackground>
    </Screen>
  );
}

const stylesheet = createStyleSheet(() => ({
  saoPauloImage: {
    width: '80%',
  },
}));
