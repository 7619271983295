import { useStyles } from '@ui/services';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export function useAppSafeArea() {
  const { top, bottom } = useSafeAreaInsets();
  const {
    theme: { spacings },
  } = useStyles();

  return {
    top: Math.max(top, spacings.s20),
    bottom: Math.max(bottom, spacings.s20),
  };
}
