import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@infra';
import { socialService } from '../socialService';

export function useGetAllSocial() {
  const socialQuery = useQuery({
    queryKey: [QueryKeys.GetAllSocial],
    queryFn: () => socialService.getAllSocial(),
    staleTime: 30000, // 30 seconds
  });

  return {
    socialQuery,
    socialData: socialQuery.data,
    isLoading: socialQuery.isLoading,
    isError: socialQuery.isError,
    isFetching: socialQuery.isFetching,
    refetch: socialQuery.refetch,
  };
}
