import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@infra';
import { configService } from '../configService';

export function useGetAllConfig() {
  const configQuery = useQuery({
    queryKey: [QueryKeys.GetAllConfig],
    queryFn: () => configService.getAllConfig(),
    staleTime: 30000, // 30 seconds
  });

  return {
    configQuery,
    configData: configQuery.data,
    isLoading: configQuery.isLoading,
    isError: configQuery.isError,
    isFetching: configQuery.isFetching,
    refetch: configQuery.refetch,
  };
}
