import { textVariants, typography } from './typography';
import { sharedColors } from './colors';
import { borderRadii } from './borderRadii';
import { breakpoints } from './breakpoints';
import { spacings } from './spacings';

import { boxVariants } from './boxVariants';
import { flexStyles } from './flexStyles';
import { viewStyles } from './viewStyles';

const commonTheme = {
  borderRadii,
  spacings,
  breakpoints,
  typography,
  components: {
    textVariants,
    boxVariants,
    flexStyles,
    viewStyles,
  },
};

const lightColors = {
  ...sharedColors,
  primary: sharedColors.redPrimary,
  primaryContrast: sharedColors.white,

  buttonPrimary: sharedColors.redPrimary,

  background: sharedColors.white,
  backgroundContrast: sharedColors.black,

  backgroundContrastTransparent: 'rgba(1, 1, 1, 0.1)',
} as const;

const darkColors = {
  ...sharedColors,

  primary: sharedColors.redPrimary,
  primaryContrast: sharedColors.white,

  buttonPrimary: sharedColors.redPrimary,

  background: sharedColors.black,
  backgroundContrast: sharedColors.white,

  backgroundContrastTransparent: 'rgba(255, 255, 255, 0.1)',
} as const;

export const lightTheme = {
  ...commonTheme,
  colors: {
    ...lightColors,
  },
} as const;

export const darkTheme = {
  ...commonTheme,
  colors: {
    ...darkColors,
  },
} as const;

export type Theme = typeof lightTheme;
export type ThemeColors = typeof lightColors | typeof darkColors;
