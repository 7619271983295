import {
  createNavigationContainerRef,
  DrawerActions,
} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

function navigationCheck(moveCallback: () => void): void {
  if (!navigationRef.isReady()) {
    setTimeout(() => navigationCheck(moveCallback), 50);
  } else {
    moveCallback?.();
  }
}

export function navigate(name: any, params: any) {
  navigationCheck(() => {
    navigationRef.navigate(name, params);
  });
}

export function toggleDrawer() {
  navigationCheck(() => {
    navigationRef.dispatch(DrawerActions.toggleDrawer());
  });
}
