import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import RNMarkdown, {
  MarkdownProps as RNMarkdownProps,
} from 'react-native-markdown-display';

import { useStyles } from '@ui/services';

interface MarkdownProps extends Omit<RNMarkdownProps, 'style'> {
  content: string;
}

export function Markdown({ content, ...props }: MarkdownProps) {
  const markdownStyle = useMarkdownStyles();
  return (
    <RNMarkdown {...props} style={markdownStyle}>
      {content}
    </RNMarkdown>
  );
}

function useMarkdownStyles() {
  const {
    theme: { colors },
  } = useStyles();

  return useMemo(
    () =>
      StyleSheet.create({
        body: {
          color: colors.backgroundContrast,
        },
        heading1: {
          color: colors.backgroundContrast,
        },
        heading2: {
          color: colors.backgroundContrast,
        },
        heading3: {
          color: colors.backgroundContrast,
        },
        heading4: {
          color: colors.backgroundContrast,
        },
        heading5: {
          color: colors.backgroundContrast,
        },
        heading6: {
          color: colors.backgroundContrast,
        },
        hr: {
          color: colors.backgroundContrast,
        },
        strong: {
          color: colors.backgroundContrast,
        },
        text: {
          color: colors.backgroundContrast,
        },
        pre: {
          color: colors.backgroundContrast,
        },
        inline: {
          color: colors.backgroundContrast,
        },
        span: {
          color: colors.backgroundContrast,
        },
      }),
    [colors],
  );
}
