import React, { useMemo } from 'react';

import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { SintoniaThemeRuntime, useStyles } from '@ui/services';
import { DefaultTheme, createStaticNavigation } from '@react-navigation/native';

import { RootStack } from './Root';

import { InfraProvider } from '../infra';
import { navigationRef } from './RootNavigation';

const StaticNavigation = createStaticNavigation(RootStack);

interface RouteProps {
  onReady?: () => void;
}

export function Routes({ onReady }: RouteProps) {
  const { theme } = useStyles();

  const RouteTheme = useMemo(
    () => ({
      ...DefaultTheme,
      dark: SintoniaThemeRuntime.colorScheme === 'dark',
      colors: {
        primary: theme.colors.primary,
        background: theme.colors.background,
        card: theme.colors.background,
        text: theme.colors.backgroundContrast,
        border: theme.colors.gray3,
        notification: theme.colors.primary,
      },
    }),
    [theme.colors],
  );

  return (
    <GestureHandlerRootView>
      <SafeAreaProvider>
        <InfraProvider>
          <StaticNavigation
            ref={navigationRef}
            onReady={onReady}
            theme={RouteTheme}
          />
        </InfraProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
}
