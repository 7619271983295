import BootSplash, {
  UseHideAnimation,
  UseHideAnimationConfig,
} from 'react-native-bootsplash';

export interface ISplashScreen {
  hide: () => Promise<void>;
  isVisible: () => Promise<boolean>;
  useHideAnimation: (config: UseHideAnimationConfig) => UseHideAnimation;
}

export const splashScreen: ISplashScreen = {
  hide: () => BootSplash.hide(),
  isVisible: () => BootSplash.isVisible(),
  useHideAnimation: BootSplash.useHideAnimation,
};
