import React, { forwardRef } from 'react';
import type { TextProps as RNTextProps, TextStyle } from 'react-native';
import type { TextVariants, ThemeColors } from '@ui/theme';

import { createStyleSheet, useStyles } from '@ui/services';
import { RNNativeText, UnsupportedNativeTextProps } from './RNText';

export interface TextProps
  extends Omit<RNTextProps, UnsupportedNativeTextProps> {
  variant?: TextVariants;
  color?: keyof ThemeColors;
  textAlign?: TextStyle['textAlign'];
}

export const Text = forwardRef<unknown, TextProps>(
  (
    {
      children,
      style,
      variant = 'paragraphMedium',
      color = 'backgroundContrast',
      textAlign,
      ...props
    },
    ref,
  ) => {
    const { styles } = useStyles(stylesheet, {
      variant,
      color,
    });

    return (
      <RNNativeText
        //@ts-expect-error
        ref={ref}
        style={[styles.container, { textAlign }, style]}
        {...props}>
        {children}
      </RNNativeText>
    );
  },
);

const stylesheet = createStyleSheet(theme => ({
  container: {
    variants: {
      variant: {
        ...theme.components.textVariants,
      },
      color: {
        transparent: {
          color: theme.colors.transparent,
        },
        primary: {
          color: theme.colors.primary,
        },
        primaryContrast: {
          color: theme.colors.primaryContrast,
        },
        redPrimary: {
          color: theme.colors.redPrimary,
        },
        redPrimaryLight: {
          color: theme.colors.redPrimaryLight,
        },
        background: {
          color: theme.colors.background,
        },
        backgroundContrast: {
          color: theme.colors.backgroundContrast,
        },
        backgroundContrastTransparent: {
          color: theme.colors.backgroundContrastTransparent,
        },
        buttonPrimary: {
          color: theme.colors.buttonPrimary,
        },
        // Grayscale
        black: {
          color: theme.colors.black,
        },
        white: {
          color: theme.colors.white,
        },
        gray1: {
          color: theme.colors.gray1,
        },
        gray2: {
          color: theme.colors.gray2,
        },
        gray3: {
          color: theme.colors.gray3,
        },
        gray4: {
          color: theme.colors.gray4,
        },
        gray5: {
          color: theme.colors.gray5,
        },
        default: {
          color: theme.colors.backgroundContrast,
        },
      },
    },
  },
}));
